@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@400;700;900&display=swap');
@import '../node_modules/bootstrap/scss/bootstrap';

body,
html {
  background-color: theme-color('light');
  font-family: 'Raleway', Helvetica, sans-serif;
}

.navbar-collapse {
  &.show,
  &.collapsing {
    border-top: 1px solid $border-color;
  }
}

h1 {
  font-family: 'Raleway', Helvetica, sans-serif;
  font-weight: 900;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  font-size: 1.5rem;
}

h2 {
  font-weight: 700;
  font-size: 1rem;

  &.resume {
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: theme-color('primary');
  }
}

a.link-secondary {
  color: theme-color('secondary');
}

.min-height-page {
  min-height: 100vh;
}

a.link-invisible {
  color: $body-color;
}
