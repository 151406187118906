@import '../../../node_modules/bootstrap/scss/bootstrap';

.projects-masonry-grid {
  display: flex;
  margin-left: -1.5 * $spacer;
  margin-right: -1.5 * $spacer;
  padding-top: 1.5 * $spacer;
  width: auto;
}

.projects-masonry-column {
  background-clip: padding-box;
}
